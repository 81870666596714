import React from 'react'
import Navbar from '../Components/Navbar'
import ContactContainer from '../Components/ContactContainer'
import Footer from '../Components/Footer'

const Contact = () => {
  return (
    <React.Fragment>
        <Navbar/>
        <ContactContainer/>
        <Footer/>
    </React.Fragment>
  )
}

export default Contact ;