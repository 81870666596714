import React from 'react';
import './Style/NoDataFound.css'
import { useNavigate ,useLocation} from 'react-router-dom';
import {useAuth} from './useAuth'

const NoDataFound = () => {
  const user = useAuth() ;
    const navigate = useNavigate() ;

    const handleAddEventClick = () => {
        if(user) {
          navigate('/addevent');
        }
        else {
          navigate('/login');
        }
    };
  return (
    <div className='no-data-found'>
      <div>
        <p>No Data Available !</p><br />
        <button className='add-event-button' onClick={handleAddEventClick}>
            Add Event
        </button>
      </div>
    </div>
  );
};

export default NoDataFound;
