import React, { useState, useEffect } from 'react';
import './Style/Navbar.css';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './useAuth';
import { auth } from '../config/firebase'; // Import 'auth' from your Firebase config file.
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import NavbarSlider from './NavbarSlider'
const Navbar = () => {
  const navigate = useNavigate();
  const goHome = () => {
    navigate('/');
  };
  const goMyEvents = () => {
    navigate('/my-events');
  };
  const goAbout = () => {
    navigate('/about');
  };
  const goAddEvent = () => {
    navigate('/addevent');
  };
  const goContact = () => {
    navigate('/contact');
  };
  const goCheck = () => {
    navigate('/list-of-not-verified');
  };
  const user = useAuth();

  const handleLogout = async () => {
    if (user === null) {
      navigate('/signup');
    } else {
      try {
        await auth.signOut();
        navigate('/');
      } catch (error) {
        console.log('Error logging out:', error);
      }
    }
  };

  const [showButton, setShowButton] = useState(false);
  const [navState, setNavState] = useState(false);

  const trackWindowWidth = () => {
    if (window.innerWidth <= 800) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  };

  useEffect(() => {
    trackWindowWidth();
    window.addEventListener('resize', trackWindowWidth);
    return () => {
      window.removeEventListener('resize', trackWindowWidth);
    };
  }, []);

  return (
    <div className="Navbar">
      {showButton && (
        <FontAwesomeIcon
          icon={faBars}
          cursor="pointer"
          className="Bars"
          onClick={() => setNavState(!navState)}
        />
      )}
      {navState && showButton ? (
          <React.Fragment>
            <div className="show-nav">
              <NavbarSlider setNavState={setNavState} />
            </div>
          </React.Fragment>
        ) : null}
      <h1 style={{ color: '#050a30' ,cursor:'pointer'}} onClick={goHome}>
        <span style={{ color: '#c70039' }}>E</span>ventii
      </h1>
      {!showButton && (
        <React.Fragment>
          <ul>
            <li onClick={goHome}>Home</li>
            <li onClick={goAbout}>About us</li>
            <li onClick={goContact}>Contact us</li>
            {user !== null ? (
              <React.Fragment>
                <li onClick={goMyEvents}>My Events</li>
                <li onClick={goAddEvent}>Add Event</li>
              </React.Fragment>
            ) : null}
            {user?.uid === 'rm1c2QIoYBT3TELOr1b0rbEWX742' ? (
              <li onClick={goCheck}>not verified</li>
            ) : (
              null
            )}
          </ul>
          {user === null ? (
            <button onClick={handleLogout}>Sign up</button>
          ) : (
            <button onClick={handleLogout}>LogOut</button>
          )}
          
        </React.Fragment>
      )}
    </div>
  );
};

export default Navbar;
