import React from 'react'
import Navbar from '../Components/Navbar'
import AboutContainer from '../Components/AboutContainer'
import Footer from '../Components/Footer'

const About = () => {
  return (
    <React.Fragment>
        <Navbar/>
        <AboutContainer/>
        <Footer/>
    </React.Fragment>
  )
}

export default About