import React, { useState } from "react";
import "./Style/NavbarSlider.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from '@fortawesome/free-solid-svg-icons';
import {useAuth} from './useAuth'
import { useNavigate } from "react-router-dom";
import {auth} from '../config/firebase'

const NavbarSlider = ({setNavState}) => {
    const user = useAuth()
    const navigate = useNavigate();
    const goHome = () => {
        navigate('/');
      };
    const goMyEvents = () => {
        navigate('/my-events');
      };
    const goAddEvent = () => {
        navigate('/addevent');
      };
    const goAbout = () => {
        navigate('/about');
      };
    const goContact = () => {
        navigate('/contact');
      };
    const goCheck = () => {
        navigate('/list-of-not-verified');
      };
    const handleLogout = async () => {
        if (user === null) {
          navigate('/signup');
        } else {
          try {
            await auth.signOut();
            navigate('/');
          } catch (error) {
            console.log('Error logging out:', error);
          }
        }
      };
    
  return (
    <div className="NavbarSliderBackground">
      <div className="NavbarSlider">
          <div style={{width:'100%' , textAlign:'right'}}>
              <FontAwesomeIcon
                icon={faBars}
                cursor="pointer"
                color="white"
                className="Bars"
                onClick={() => {
                    setNavState(false);
                    
                  }}
                  style={{padding:'2rem 1rem 0 0'}}
              />
            
          </div>
          <div className="nav-element">
            <ul>
                <li onClick={goHome}>Home</li> <br />
                <li onClick={goAbout}>About us</li>
                <li onClick={goContact}>Contact us</li> 
                {user !== null ? (
                    <>
                      <li onClick={goMyEvents}>My Events</li>
                      <li onClick={goAddEvent}>Add Event</li>
                      {user?.uid === 'rm1c2QIoYBT3TELOr1b0rbEWX742' ? (
                        <li onClick={goCheck}>not verified</li>
                      ) : (
                        null
                      )}
                      <li onClick={handleLogout}>Log Out</li>
                      
                    </>
                ) : <li onClick={handleLogout}>Sign Up</li>
                }
            </ul>
            
            
            
          </div>
        </div>
      </div>
    
  );
};

export default NavbarSlider;
