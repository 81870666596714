import React, { useState, useEffect } from 'react';
import './Style/Section.css';
import im1 from '../Images/im1.jpg';
import im3 from '../Images/im3.jpg';
import im4 from '../Images/im4.jpg';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './useAuth'
const Section = () => {
    const navigate = useNavigate() ;
    const images = [im1, im3, im4];
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const user = useAuth();

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 10000);

        return () => clearInterval(interval);
    }, [images.length]);
    const goAddEvent = () => {
        if (user) {
          navigate('/addevent');
          console.log(user)
        } else {
          navigate('/login');
        }
      };

      const goSignIn = () => {
        navigate('/login')
      };
      const goMyEvents = () => {
        navigate('/my-events')
      };
    return (
        <div className='Section'>
            <div className='content-section'>
                <div>
                    <h2>Events, meetups &amp; <span style={{ color: '#c70039' }}>Conferences</span></h2>
                    <p>
                    Explore a lively mix of exciting events, 
                    including academic conferences, informative seminars, cultural festivals, 
                    and thrilling tech competitions.
                    </p>
                    <div className='btn'>
                        <button onClick={goAddEvent}>Add Event</button> <br />
                        {user === null ? 
                        (
                            <button onClick={goSignIn}
                                style={{
                                    backgroundColor:'white',
                                    color:'#c70039',
                                    border:'1px solid #c70039'
                                }}>Log In</button>
                        ) :
                        (
                            <button onClick={goMyEvents}
                                style={{
                                    backgroundColor:'white',
                                    color:'#c70039',
                                    border:'1px solid #c70039'
                            }}>My Events</button>
                        )}
                    </div>
                </div>
            </div>
            <div className='images-section'>
                <div>
                    {images.map((image, index) => (
                        <img
                            key={index}
                            src={image}
                            alt={` ${index + 1}`}
                            style={{
                                display: index === currentImageIndex ? 'block' : 'none',
                                
                            }}
                        />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Section;
