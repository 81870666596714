import React from 'react'
import Navbar from '../Components/Navbar'
import Login from '../Components/Login'
import Footer from '../Components/Footer'
const LoginPage = () => {
  return (
    <div >
        <Navbar/>
        <Login/>
        <Footer/>
    </div>
  )
}

export default LoginPage