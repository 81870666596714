import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import { useAuth } from '../Components/useAuth';
import useEvents from '../Components/Events';
import useUsers from '../Components/Users'
import EventItem from '../Components/EventItem'
import '../Components/EventList'
import NoDataFound from '../Components/NoDataFound';

const NotVerified = () => {
    const user = useAuth()
    const events = useEvents() 
    
    
  return (
    <div style={{backgroundColor:'rgb(245, 245, 245)'}}>
        <Navbar/>
        <div className='EventList'>
            {events.length > 0 ?
            (
              events.map((event) => (
                event.data.checked === false ? (
                    <EventItem key={event.id} event={event} />
                  ) : null
                ))
            ) :
            (
              <NoDataFound/>
            )

            }
        </div>
        <Footer/>
    </div>
  )
}

export default NotVerified ;