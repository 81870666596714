import React from 'react'
import Navbar from '../Components/Navbar'
import EventHome from '../Components/EventHome'
import Footer from '../Components/Footer'
const EventPage = () => {
  return (
    <div>
        <Navbar/>
        <EventHome/>
        <Footer/>
    </div>
  )
}

export default EventPage