import React, { useState, useEffect } from 'react';
import './Style/EventItem.css';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import CustomAlert from './CustomAlert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faMapMarkerAlt, faTags, faTrashAlt, faCheck } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useLocation } from 'react-router-dom';
import { doc, deleteDoc, updateDoc } from 'firebase/firestore';
import { db } from '../config/firebase';
import { useAuth } from './useAuth';
import useUsers from './Users';
import { Metronome } from '@uiball/loaders';
import { Waveform } from '@uiball/loaders';

const EventItem = ({ event }) => {
  const user = useAuth();
  const users = useUsers();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const closeAlert = () => {
    setShowAlert(false);
  };

  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: '0px 0px -100px 0px',
  });

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  const handleAnimationComplete = () => {
    // Additional actions when the animation completes
    console.log('EventItem animation completed!');
  };

  // Function to format the date
  const formatDate = (dateString) => {
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const goEvent = () => {
    navigate('/event-detail', { state: { event } });
  };

  const deleteEvent = async (id) => {
    const eventRef = doc(db, 'events', id);
    await deleteDoc(eventRef);
    setAlertMessage('Event added successfully!');
    setShowAlert(true);
    window.location.reload();
  };

  // Fetch current user
  const currentUser = user ? users.find((obj) => obj.data.uid === user.uid) : null;

  // accept the event
  const handleUpdate = async () => {
    const Event = doc(db, 'events', event.id);
    await updateDoc(Event, {
      checked: true,
    });
    alert('update successfully');
    window.location.reload();
  };

  return (
    <motion.div
      className='Event-Card'
      animate={controls}
      initial='hidden'
      ref={ref}
      variants={{
        hidden: { opacity: 0, y: -20 },
        visible: { opacity: 1, y: 0 },
      }}
      onAnimationComplete={handleAnimationComplete}
      whileHover={{ scale: 1.05 }}
    >
      <div className='Event-pic'>
        {event.data.image ? (
          <img src={event.data.image} alt='' className='img' />
        ) : (
          <Metronome size={40} speed={1.6} color='black' className='img' />
        )}
        {user && event.data.user === currentUser?.id && pathname === '/my-events' ? (
          <div className='delete-icon'>
            <FontAwesomeIcon
              onClick={() => deleteEvent(event.id)}
              icon={faTrashAlt}
              style={{
                marginRight: '0.5rem',
                fontSize: '1rem',
                paddingTop: '0.3rem',
                cursor: 'pointer',
              }}
              color='white'
            />
          </div>
        ) : null}
      </div>
      <div className='Event-detail' onClick={goEvent}>
        <div className='date-event'>
          <FontAwesomeIcon
            icon={faCalendarAlt}
            style={{
              marginRight: '0.5rem',
              fontSize: '0.7rem',
              paddingTop: '0.3rem',
            }}
            color='white'
          />
          <p style={{ color: 'white' }}>{formatDate(event.data.date)}</p>
        </div>
        <div>
          <FontAwesomeIcon
            icon={faTags}
            style={{
              marginRight: '0.5rem',
              fontSize: '0.7rem',
              paddingTop: '0.3rem',
            }}
            color='#050a30'
          />
          <p>{event.data.category}</p>
        </div>
        <div className='event-location'>
          <FontAwesomeIcon
            icon={faMapMarkerAlt}
            style={{
              marginRight: '0.5rem',
              fontSize: '0.7rem',
              paddingTop: '0.3rem',
            }}
            color='#050a30'
          />
          <p>{event.data.place}</p>
        </div>
        <h1>{event.data.name.substring(0, 16)} <span>...</span></h1>
        <div className='event-description'>
          <p>
            {event.data.description.substring(0, 100)} <span>...</span>
          </p>
        </div>
      </div>
      <button className='btn-explore' onClick={goEvent}>
        Explore
      </button>

      {user && user?.uid === 'rm1c2QIoYBT3TELOr1b0rbEWX742' && pathname === '/list-of-not-verified' ? (
        <button className='btn-accept' onClick={handleUpdate}>
          Accept
        </button>
      ) : null}

      {showAlert && <CustomAlert message={alertMessage} onClose={closeAlert} />}
    </motion.div>
  );
};

export default EventItem;
