import React from 'react'
import './Style/EventHome.css'
import {useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faEnvelope ,faMapMarkerAlt, faTags ,faEarth,faPhone} from '@fortawesome/free-solid-svg-icons';
import { faLinkedin, faFacebook } from '@fortawesome/free-brands-svg-icons';

const EventHome = () => {
    const location = useLocation();
    const { event } = location.state;
    const formatDate = (dateString) => {
        const options = {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        };
        return new Date(dateString).toLocaleDateString(undefined, options);
      };
  return (
    <div className='EventHome'>
        <div className='Event-container'>
            <div className='event-header'>
                <h1>{event.data.name}</h1>
            </div>
            <div className='event-location'>
                    <FontAwesomeIcon icon={faMapMarkerAlt} style={{ marginRight: '0.5rem', fontSize: '0.9rem',paddingTop:'0.3rem' }} color='#050a30' />
                    <p>{event.data.place}</p>
            </div>
            <div className='event-img'>
                <img src={event.data.image} alt="" />
            </div>
            
            <div className='event-info' style={{justifyContent:'space-around'}}>
                <div>
                    <div style={{display:'flex' ,textTransform:'capitalize'}}>
                    <FontAwesomeIcon icon={faCalendarAlt} style={{ marginRight: '0.5rem', fontSize: '0.7rem',paddingTop:'0.3rem' }} color='#050a30' />
                    <p>Date</p>
                    </div>
                    <p style={{color:'gray', paddingLeft:'1rem',textTransform:'capitalize'}}>{formatDate(event.data.date)}</p>
                </div>
                <div>
                    <div style={{display:'flex' ,textTransform:'capitalize'}}>
                    <FontAwesomeIcon icon={faTags} style={{ marginRight: '0.5rem', fontSize: '0.7rem',paddingTop:'0.3rem' }} color='#050a30' />
                    <p>Event category</p>
                    </div>
                    <p style={{color:'gray', paddingLeft:'1rem',textTransform:'capitalize'}}>{event.data.category}</p>
                </div>
                <div>
                <div style={{display:'flex' ,textTransform:'capitalize'}}>
                    <FontAwesomeIcon icon={faEarth} style={{ marginRight: '0.5rem', fontSize: '0.7rem',paddingTop:'0.3rem' }} color='#050a30' />
                    <p>Organized By</p>
                    </div>
                    <p style={{color:'gray', paddingLeft:'1rem' ,textTransform:'capitalize'}}>{event.data.organizer}</p>
                </div>
            </div>
            
            <div style={{width:'100%',padding:'1rem', fontSize:'1rem', color:'#050a30'}}>
                <br /><h3>Description :</h3>
            </div>
            <div className="description-event">
                <p>{event.data.description}</p>
            </div>
            <div className='event-contact'>
                <div style={{width:'100%',padding:'1rem', fontSize:'1rem', color:'#050a30'}}>
                    <p>For more informations :</p>
                </div>
                <div>
                    <div style={{display:'flex' ,paddingLeft:'1rem'}}>
                        <FontAwesomeIcon icon={faPhone} style={{ marginRight: '0.5rem', fontSize: '0.7rem',paddingTop:'0.3rem' }} color='#c70039' />
                        <p>phone number : +216 {event.data.contact}</p> <br />
                    </div>
                    <div style={{display:'flex',paddingLeft:'1rem'}}>
                        <FontAwesomeIcon icon={faEnvelope} style={{ marginRight: '0.5rem', fontSize: '0.7rem',paddingTop:'0.3rem' }} color='#c70039' />
                        <p>Email : {event.data.email}</p> <br />
                    </div>
                    <div style={{paddingLeft:'1rem' }}>
                        <p>Follow us on : </p> 
                        {/* <FontAwesomeIcon icon={faFacebook} style={{ marginRight: '1rem',marginLeft:'0.5rem', fontSize: '1.2rem',paddingTop:'0.3rem',cursor:'pointer'  }} color='rgb(15, 15, 233)' />
                        <FontAwesomeIcon icon={faLinkedin} style={{ marginRight: '1rem', fontSize: '1.2rem',paddingTop:'0.3rem',cursor:'pointer' }} color='rgb(15, 15, 233)' /> */}
                        <ul>
                            <li>
                                <a href={event.data?.facebook} style={{textDecoration:'none', color:'#050a30'}}>Facebook</a>
                            </li>
                            <li>
                                <a href={event.data?.linkedin} style={{textDecoration:'none',color:'#050a30'}}>LinkedIn</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
  )
}

export default EventHome