import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {getFirestore} from 'firebase/firestore';
import { getStorage } from "firebase/storage";
import {getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyBBKlaI46wCSytB7Wl08MUuArTwI7p2KA0",
  authDomain: "glucho-test.firebaseapp.com",
  projectId: "glucho-test",
  storageBucket: "glucho-test.appspot.com",
  messagingSenderId: "1022503490027",
  appId: "1:1022503490027:web:486f235edc2324ad2089ae",
  measurementId: "G-EB2SVJPBSL"
};


const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
const db = getFirestore(app) ;
const storage = getStorage(app);
const auth = getAuth(app);


export { app, db, storage ,auth }; // Export the storage along with app and db





