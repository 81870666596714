import React from 'react'
import Navbar from '../Components/Navbar'
import EventForm from '../Components/EventForm'
import Footer from '../Components/Footer'
const AddEvent = () => {
  return (
    <div>
        <Navbar/>
        <EventForm/>
        <Footer/>
    </div>
  )
}

export default AddEvent