import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import { useAuth } from '../Components/useAuth';
import useEvents from '../Components/Events';
import useUsers from '../Components/Users'
import EventItem from '../Components/EventItem'
import '../Components/EventList'
import NoDataFound from '../Components/NoDataFound';

const MyEvents = () => {
    const user = useAuth()
    const events = useEvents() 
    const users = useUsers()
    const currentUser = users? users.find((obj) =>obj.data.uid === user.uid) : null ;
    const myEvents = events && currentUser  ? events.filter((event) => event.data.user === currentUser.id) : [] ;
    
  return (
    <div style={{backgroundColor:'rgb(245, 245, 245)'}}>
        <Navbar/>
        <div className='EventList' style={{height:'96vh'}}>
            {myEvents.length > 0 ?
            (
              myEvents.map((event) => (
                <EventItem key={event.id} event={event} />
                ))
            ) :
            (
              <NoDataFound/>
            )

            }
        </div>
        <Footer/>
    </div>
  )
}

export default MyEvents