import React, { useState } from 'react';
import './Style/EventForm.css';
import CustomAlert from './CustomAlert';
import { db, storage } from '../config/firebase'; // Import the firestore and storage instances
import { addDoc, collection } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import universities from './UniversityList'
import { useAuth } from './useAuth';
import useUsers from './Users'
const EventForm = () => {
  const user = useAuth() ;
  const users = useUsers()
  const currentUser = users.find((obj) =>obj.data.uid === user.uid);

  const [categoryOther, setCategoryOther] = useState(false);
  const [locationOther, setLocationOther] = useState(false);

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const closeAlert = () => {
    setShowAlert(false);
  };


  const [formData, setFormData] = useState({
    name: '',
    date: '',
    category: '',
    place: '',
    description: '',
    contact: '',
    email:'',
    organizer: '',
    facebook: '',
    linkedin : '' ,
    image: null, // New state variable to store the selected image
    
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleCategoryChange = (e) => {
    const selectedCategory = e.target.value;
    if (selectedCategory === 'Other') {
      setCategoryOther(true);
    } else {
      setCategoryOther(false);
      setFormData({ ...formData, category: selectedCategory });
    }
  };

  const handleLocationChange = (e) => {
    const selectedLocation = e.target.value;
    if (selectedLocation === 'Other') {
      setLocationOther(true);
    } else {
      setLocationOther(false);
      setFormData({ ...formData, place: selectedLocation });
    }
  };

  const handleImageChange = (e) => {
    const imageFile = e.target.files[0];
    setFormData({ ...formData, image: imageFile });
  
    // Display the selected file name
    const fileNameDisplay = document.getElementById('file-name-display');
    if (fileNameDisplay) {
      fileNameDisplay.textContent = imageFile ? imageFile.name : '';
    }
  };
  
  const [emptyFields, setEmptyFields] = useState([]);
  const eventRef = collection(db, 'events');

  const addEvent = async () => {
    try {
      setIsLoading(true);
      if (
        !formData.name ||
        !formData.date ||
        !formData.category ||
        !formData.place ||
        !formData.description ||
        !formData.contact ||
        !formData.email ||
        !formData.organizer ||
        !formData.facebook ||
        !formData.linkedin ||
        !formData.image
      ) {
        setIsLoading(false);
        setAlertMessage('Please fill in all the required fields.');
        setShowAlert(true);
        const emptyFieldList = ['name', 'date', 'category', 'place', 'description', 'contact', 'email', 'organizer', 'facebook', 'linkedin', 'image']
        .filter(fieldName => !formData[fieldName])
        .map(fieldName => fieldName);

      setEmptyFields(emptyFieldList);
          return;
        }
      // Upload the image to Firebase Storage (if an image is selected)
      let imageUrl = null;
      if (formData.image) {
        const storageRef = ref(storage, `${formData.image.name}`);
        const uploadTask = uploadBytesResumable(storageRef, formData.image);

        uploadTask.on(
          'state_changed',
          (snapshot) => {
            
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
          },
          (error) => {
            
            console.log(error);
          },
          async () => {
            
            imageUrl = await getDownloadURL(uploadTask.snapshot.ref);

            // Create a new document in the 'events' collection with the image URL
            await addDoc(eventRef, {
              name: formData.name,
              date: formData.date,
              place: formData.place,
              category: formData.category,
              description: formData.description,
              contact: formData.contact,
              email : formData.email ,
              organizer: formData.organizer,
              fb: formData.facebook,
              linkedin : formData.linkedin,
              image: imageUrl, // Store the image URL in Firestore
              user : currentUser.id,
              checked : false
            });

            setFormData({
              name: '',
              date: '',
              category: '',
              place: '',
              description: '',
              contact: '',
              email :'',
              organizer: '',
              facebook: '',
              linkedin:'',
              image: null,
            });

            setIsLoading(false);
            setAlertMessage('Thank you for submitting your event to Eventii!Please note that our admin team will review it before publication ');
            setShowAlert(true);
          }
        );
      } else {
        setIsLoading(false);
        setAlertMessage('Add a cover please!');
        setShowAlert(true);
      }
    } catch (error) {
      console.log(error);
    }
  };



  return (
    <div className='EventForm'>
      {isLoading ? ( 
        <div className='loading-container'>
          <div className='loading-spinner'></div>
          <div className='loading-message'>Adding the event...</div>
        </div>
      ) : (
        <div className='Form'>
        <div>
          <label htmlFor='name'>name :</label> <br />
          <input
            type='text'
            placeholder='event name'
            name='name'
            value={formData.name}
            onChange={handleChange}
            className={emptyFields.includes('name') ? 'red-border' : ''}
          />
        </div>
        <div>
          <label htmlFor='date'>date :</label><br />
          <input
            type='date'
            name='date'
            value={formData.date}
            onChange={handleChange}
            className={emptyFields.includes('date') ? 'red-border' : ''}
          />
        </div>
        <div>
          <label htmlFor='category'>category :</label><br />
          {categoryOther ? (
            <input
              type='text'
              name='category'
              placeholder='Enter your own category'
              value={formData.category}
              onChange={handleChange}
              className={emptyFields.includes('category') ? 'red-border' : ''}
            />
          ) : 
          (
            <select
            name='category'
            value={formData.category}
            onChange={handleCategoryChange}
            className={emptyFields.includes('category') ? 'red-border' : ''}
          >
            <option value=''>select category</option>
            <option value='Robotics Competitions'>Robotics Competitions</option>
            <option value='Hackathons and Coding Competitions'>Hackathons and Coding Competitions</option>
            <option value='Workshops and Tech Conferences'>Workshops and Tech Conferences</option>
            <option value='Innovation Showcases'>Innovation Showcases</option>
            <option value='Tech Career Fairs'>Tech Career Fairs</option>

            <option value='Entrepreneurship Competitions'>Entrepreneurship Competitions</option>
            <option value='Leadership and Personal Development'>Leadership and Personal Development</option>
            <option value='Finance and Investment Events'>Finance and Investment Events</option>
            <option value='Other'>Other (write another category)</option>
          </select>
          )
          }
        </div>
        <div>
          <label htmlFor='place'>place :</label><br />
          {locationOther ? (
            <input
              type='text'
              name='place'
              placeholder='Enter your own location'
              value={formData.place}
              onChange={handleChange}
              className={emptyFields.includes('place') ? 'red-border' : ''}
            />
          ) : 
          (
            <select
          id='place'
          name='place'
          value={formData.place}
          onChange={handleLocationChange}
          className={emptyFields.includes('place') ? 'red-border' : ''}
          
        >
          
          <option value="">select location</option>
          {universities.map((university, index) => (
            <option key={index} value={university}>
              {university}
            </option>
          ))}
          <option value='Online'>Online</option>
          <option value='Other'>Other (write another location) </option>
        </select>
          )
          }
          
        </div>
        <div>
          <label htmlFor='description'>description :</label><br />
          <textarea
            rows='5'
            placeholder='short description about the event'
            name='description'
            value={formData.description}
            onChange={handleChange}
            className={emptyFields.includes('description') ? 'red-border' : ''}
          ></textarea>
        </div>
        <div>
          <label htmlFor='contact'>contact :</label><br />
          <input
            type='number'
            placeholder='phone number'
            name='contact'
            value={formData.contact}
            onChange={handleChange}
            className={emptyFields.includes('contact') ? 'red-border' : ''}
          />
        </div>
        <div>
          <label htmlFor='contact'>email :</label><br />
          <input
            type='email'
            placeholder='alex@gmail.com'
            name='email'
            value={formData.email}
            onChange={handleChange}
            className={emptyFields.includes('email') ? 'red-border' : ''}
          />
        </div>
        <div>
          <label htmlFor='organizer'>organizer :</label><br />
          <input
            type='text'
            placeholder='club or association'
            name='organizer'
            value={formData.organizer}
            onChange={handleChange}
            className={emptyFields.includes('organizer') ? 'red-border' : ''}
          />
        </div>
        <div>
          <label htmlFor='facebook'>facebook page :</label><br />
          <input
            type='text'
            placeholder='facebook'
            name='facebook'
            value={formData.facebook}
            onChange={handleChange}
            className={emptyFields.includes('facebook') ? 'red-border' : ''}
          />
        </div>
        <div>
          <label htmlFor='linkedin'>linkedin page :</label><br />
          <input
            type='text'
            placeholder='linkedin'
            name='linkedin'
            value={formData.linkedin}
            onChange={handleChange}
            className={emptyFields.includes('linkedin') ? 'red-border' : ''}
          />
        </div>
        <div>
          <label htmlFor='image'>Image :</label> <br />
          <input
            type='file'
            name='image'
            accept='image/*'
            onChange={handleImageChange}
            className={emptyFields.includes('image') ? 'red-border' : ''}
          />
          
        </div>


        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>
          <button onClick={addEvent}>Submit</button>
        </div>
      </div>
      )}
      
      {showAlert && <CustomAlert message={alertMessage} onClose={closeAlert} />}
    </div>
  );
};

export default EventForm;
