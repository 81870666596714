import React from 'react'
import Navbar from '../Components/Navbar'
import Section from '../Components/Section'
import EventList from '../Components/EventList'
import Footer from '../Components/Footer'
const Home = () => {
  return (
    <div>
        <Navbar/>
        <Section/>
        <EventList/>
        <Footer/>
    </div>
  )
}

export default Home