import React, { useState } from 'react';
import './Style/EventList.css';
import EventItem from './EventItem';
import universities from './UniversityList';
import useEvents from './Events';
import NoDataFound from './NoDataFound';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';

const EventList = () => {
  const events = useEvents();
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [selectedLocation, setSelectedLocation] = useState('');
  const [selectedDate, setSelectedDate] = useState('all');

  const filterEvents = () => {
    return events.filter((event) => {
      const categoryMatch =
        selectedCategory === 'all' || event.data.category === selectedCategory;
      const locationMatch =
        selectedLocation === '' || event.data.place === selectedLocation;
      const currentDate = new Date();
      const eventDate = new Date(event.data.date);
      
      // Check if the event date is today
      const isToday =
        selectedDate === 'today' &&
        eventDate.toISOString().split('T')[0] ===
          currentDate.toISOString().split('T')[0];

      // Check if the event date is within the next 7 days
      const isNextWeek =
        selectedDate === 'nextWeek' &&
        eventDate >= currentDate &&
        eventDate <= new Date(currentDate.getTime() + 7 * 24 * 60 * 60 * 1000);

      // Check if the event date is within the next 30 days
      const isNextMonth =
        selectedDate === 'nextMonth' &&
        eventDate >= currentDate &&
        eventDate <= new Date(currentDate.getTime() + 30 * 24 * 60 * 60 * 1000);
      // Check if the event date is within the next 90 days
      const threeMonthsFromNow = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 3,
        currentDate.getDate()
      );
      const isNextThreeMonths =
          selectedDate === 'nextThreeMonths' &&
          eventDate >= currentDate &&
          eventDate <= threeMonthsFromNow;

      const dateMatch =
        selectedDate === 'all' || isToday || isNextWeek || isNextMonth || isNextThreeMonths;

      return categoryMatch && locationMatch && dateMatch;
    });
  };
  const [displayedEvents, setDisplayedEvents] = useState(8);
  const [showMoreClicked, setShowMoreClicked] = useState(false);

  const handleShowMoreClick = () => {
    setDisplayedEvents(displayedEvents + 8);
    setShowMoreClicked(true);
  };

  const handleShowLessClick = () => {
    setDisplayedEvents(8);
    setShowMoreClicked(false);
  };
  return (
    <div className='EventContainer'>
      <div className='search-bar'>
        <div className='search-container'>
          <select
            id='place'
            name='place'
            onChange={(e) => setSelectedLocation(e.target.value)}
          >
            <option value=''>All locations</option>
            {universities.map((university, index) => (
              <option key={index} value={university}>
                {university}
              </option>
            ))}
            <option value='Online'>Online</option>
          </select>
          <select
            name='date'
            id='date'
            onChange={(e) => setSelectedDate(e.target.value)}
          >
            
            <option value='all'>All dates</option>
            <option value='today'>Today</option>
            <option value='nextWeek'>Next week</option>
            <option value='nextMonth'>Next month</option>
            <option value='nextThreeMonths'>Next three months</option>
          </select>
          <select
            name='category'
            onChange={(e) => setSelectedCategory(e.target.value)}
          >
            <option value='all'>All categories</option>
            <option value='Robotics Competitions'>Robotics Competitions</option>
            <option value='Hackathons and Coding Competitions'>Hackathons and Coding Competitions</option>
            <option value='Workshops and Tech Conferences'>Workshops and Tech Conferences</option>
            <option value='Innovation Showcases'>Innovation Showcases</option>
            <option value='Tech Career Fairs'>Tech Career Fairs</option>

            <option value='Entrepreneurship Competitions'>Entrepreneurship Competitions</option>
            <option value='Leadership and Personal Development'>Leadership and Personal Development</option>
            <option value='Finance and Investment Events'>Finance and Investment Events</option>
            
          </select>
          
        </div>
      </div>
      <div className='Event-description'>
        <h1>Upcoming Events</h1>
      </div>
      <div className='EventList'>
        {filterEvents().length > 0 ? (
          filterEvents()
            .slice(0, displayedEvents)
            .map((event) =>
              event.data.checked === true ? <EventItem key={event.id} event={event} /> : null
            )
        ) : (
          <NoDataFound />
        )}
      </div>

      <div className='show-more'>
        {filterEvents().length > displayedEvents && !showMoreClicked  && (
          <button onClick={handleShowMoreClick} className="animated-button">Show More <FontAwesomeIcon icon={faArrowDown} /></button>
        )}
        {showMoreClicked && (
        <button onClick={handleShowLessClick} className="animated-button">Show Less <FontAwesomeIcon icon={faArrowUp} /></button>
        )}

      </div>
    </div>
  );
};

export default EventList;
