const universities = [
  'Faculté des Sciences Humaines et Sociales de Tunis',
  'Ecole Supérieure des Sciences Economiques et Commerciales de Tunis',
  'Ecole Normale Supérieure',
  'Ecole Supérieure des Sciences et Techniques de Tunis',
  'Institut Préparatoire aux Etudes d\'Ingénieurs de Tunis',
  'Institut Supérieur de Gestion de Tunis',
  'Institut Supérieur des Études Appliquées en Humanités de Zaghouan',
  'Faculté des Sciences Juridiques, Politiques et Sociales de Tunis',
  'Faculté des Sciences de Bizerte',
  'Faculté des Sciences Economiques et de Gestion de Nabeul',
  'Ecole Nationale d\'Architecture et d\'Urbanisme de Tunis',
  'Ecole Polytechnique de Tunisie',
  'Ecole Supérieure des Statistiques et d\'Analyse de l\'Information',
  'Institut des Hautes Etudes Commerciales de Carthage',
  'Institut National des Sciences Appliquées et de Technologie',
  'Institut Supérieur des Sciences Appliquées et de la Technologie de Mateur',
  'Institut Préparatoire aux Etudes Scientifiques et Techniques de la Marsa',
  'Institut Supérieur des Beaux Arts de Nabeul',
  'Institut Supérieur des Langues de Tunis',
  'Institut Supérieur des Langues Appliquées et d\'Informatique de Nabeul',
  'Institut Supérieur des Sciences et Technologies de l\'Environnement de Borj Cédria',
  'Sup\'Com',
  'Tunis Business School',
  'Faculté de Droit et des Sciences Politiques de Tunis',
  'Faculté des Sciences Economique et de Gestion de Tunis',
  'Faculté des sciences de tunis',
  'Faculté de Médecine de Tunis',
  'Ecole Nationale d\'Ingénieurs de Tunis',
  'Institut Préparatoire aux Etudes d\'Ingénieurs d\'El Manar',
  'Institut Supérieur d\'Informatique d\'El Manar',
  'Institut Supérieur des Sciences Humaines de Tunis',
  'Ecole Supérieure des Sciences et Techniques de la Santé de Tunis',
  'Institut Supérieur des Sciences infirmières de Tunis',
  'Institut de Recherche Vétérinaire de Tunis',
  'Faculté de Droit et des Sciences Economiques et Politiques de Sousse',
  'Faculté de Médecine de Sousse',
  'Faculté des Lettres et des Sciences Humaines de Sousse',
  'Institut Supérieur de Finance et de Fiscalité de Sousse',
  'Institut Supérieur d\'Informatique et des Technologies de Communication de Hammam Sousse',
  'Institut Supérieur du Transport et de la Logistique de Sousse',
  'Ecole Supérieure des Sciences et Techniques de la Santé de Sousse',
  'Institut Supérieur de Gestion de Sousse',
  'Institut des Hautes Etudes Commerciales de Sousse',
  'Institut Supérieur des Sciences Appliquées et de Technologie de Sousse',
  'Ecole Nationale d’Ingénieurs de Sousse',
  'Institut Supérieur des Sciences infirmières de Sousse',
  'Ecole Supérieure des Sciences et des technologies de Hammam Sousse',
  'Faculté des Sciences Economiques et de Gestion de Sfax',
  'Faculté de Médecine de Sfax',
  'Institut Supérieur d\'Electronique et de Communication de Sfax',
  'Institut Supérieur de Gestion Industrielle de Sfax',
  'Institut des Hautes Etudes Commerciales de Sfax',
  'Faculté des Sciences de Sfax',
  'Ecole Nationale d\'Ingénieurs de Sfax',
  'Ecole Supérieure de Commerce de Sfax',
  'Institut Supérieur d\'Administration des Affaires de Sfax',
  'Institut Supérieur d\'Informatique et de Multimédia de Sfax',
  'Institut Préparatoire aux Etudes d\'Ingénieurs de Sfax',
  'Faculté des Lettres et Sciences Humaines de Sfax',
  'Ecole Supérieure des Sciences et Techniques de la Santé de Sfax',
  'Institut Supérieur des Sciences infirmières de Sfax',
  'Faculté des Lettres, des Arts et des Humanités de la Manouba',
  'Ecole Supérieure des Sciences et Technologies du Design',
  'Ecole Supérieure de Commerce de Tunis',
  'Ecole Supérieure de Commerce Electronique de la Manouba',
  'Ecole Nationale des Sciences de l\'Informatique',
  'Institut Supérieur des Arts du Multimédia de la Manouba',
  'Institut Supérieur de Comptabilité et d\'Administration des Entreprises',
  'Institut de Presse et des Sciences de l\'Information',
  'Institut Supérieur du Sport et de l\'Education Physique de Ksar Saïd',
  'Faculté des Sciences de Monastir',
  'Faculté de Médecine de Monastir',
  'Institut Supérieur d\'Informatique et de Mathématiques de Monastir',
  'Faculté de Médecine Dentaire de Monastir',
  'Faculté de Pharmacie de Monastir',
  'Faculté des Sciences Economiques et de Gestion de Mahdia',
  'Ecole Nationale d\'Ingénieurs de Monastir',
  'Ecole Supérieure des Sciences et Techniques de la Santé de Monastir',
  'Institut Supérieur des Etudes Appliquées en Humanités de Mahdia',
  'Institut Supérieur des Métiers de la Mode de Monastir',
  'Institut Supérieur d\'Informatique de Mahdia',
  'Institut Supérieur des Sciences Appliquées et de Technologie de Mahdia',
  'Institut Supérieur des Etudes Technologiques de Sfax',
  'Institut Supérieur des Etudes Technologiques de Kebili',
  'Institut Supérieur des Etudes Technologiques de Gabès',
  'Institut Supérieur des Etudes Technologiques de Djerba',
  'Institut Supérieur des Etudes Technologiques de Kef',
  'Institut Supérieur des Etudes Technologiques de Zaghouan',
  'Institut Supérieur des Etudes Technologiques de Sousse',
  'Institut Supérieur des Etudes Technologiques de Mahdia',
  'Institut Supérieur des Etudes Technologiques de Siliana',
  'Institut Supérieur des Etudes Technologiques de Radès',
  'Institut Supérieur des Etudes Technologiques de Nabeul',
  'Institut Supérieur des Etudes Technologiques de Sidi Bouzid',
  'Institut Supérieur des Etudes Technologiques en Communications (El Ghazala)',
  'Institut Supérieur des Etudes Technologiques de Kairouan',
  'Institut Supérieur des Etudes Technologiques de Gafsa',
  'Institut Supérieur des Etudes Technologiques de Béja',
  'Institut Supérieur des Etudes Technologiques de Charguia',
  'Institut Supérieur des Etudes Technologiques de Jendouba',
  'Institut Supérieur des Etudes Technologiques de Kasserine',
  'Institut Supérieur des Etudes Technologiques de Ksar Hellal',
  'Institut Supérieur des Etudes Technologiques de Tataouine',
  'Institut Supérieur des Etudes Technologiques de Tozeur',
  'Institut Supérieur des Etudes Technologiques de Bizerte',
  'Institut supérieur des Etudes Technologiques de Médenine',
  'Faculté des Sciences Juridiques, Economiques et de Gestion de Jendouba',
  'Ecole Supérieure des Ingénieurs de l\'Equipement Rural de Medjez El Bab',
  'Institut supérieur de l\'informatique du Kef',
  'Institut Supérieur des Sciences infirmières du Kef',
  'Faculté des Sciences de Gabès',
  'Ecole Nationale d\'Ingénieurs de Gabès',
  'Institut Supérieur de Gestion de Gabès',
  'Institut Supérieur des Langues de Gabès',
  'Institut Supérieur des Arts et Métiers de Gabès',
  'Institut Supérieur d \'Informatique et de Multimédia de Gabès',
  'Institut Supérieur des Sciences Appliquées et de Technologie de Gabès',
  'Institut Supérieur des Systèmes Industriels de Gabès',
  'Institut Supérieur des Etudes Appliquées en Humanités de Médenine',
  'Institut Supérieur d\'Informatique de Medenine',
  'Institut Supérieur des Arts et Métiers de Tataouine',
  'Institut Supérieur des Sciences infirmières de Gabès',
  'Ecole Supérieure Privée d\'Ingénierie et de Technologie' ,
  'Polytechnique Sousse' ,
  'Ecole Privée d\'Ingénieurs' ,
  'Université Privée de l\'Horizon ',
  'Université Libre de Tunis' ,
  'Université Centrale' ,
  'Ecole nationale d\'électronique et des télécommunications de Sfax'
];




// Sort the universities alphabetically
const uniqueSortedUniversities = [...new Set(universities)].sort((a, b) =>
  a.localeCompare(b, 'fr', { sensitivity: 'base' })
);

export default uniqueSortedUniversities;
