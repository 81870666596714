import React from 'react'
import './Style/Footer.css'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faLinkedin ,faWhatsapp} from '@fortawesome/free-brands-svg-icons'; // Use free-brands-svg-icons for the LinkedIn icon

const Footer = () => {
  return (
    <div className='Footer'>
        <div>
            <p >Eventii by Oussama Ben Slama</p>
            <h1 style={{color:'white'}}><span style={{color:'#c70039'}}>E</span>ventii</h1>
            
        </div>
    </div>
  )
}

export default Footer