import { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../config/firebase';

function useEvents() {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const eventsRef = collection(db, 'events');
    const currentDate = new Date(); // Current date

    const getEventList = async () => {
      try {
        const response = await getDocs(eventsRef);
        const fetchedData = response.docs.map((doc) => ({
          id: doc.id,
          data: doc.data(),
        }));

        // Filter events based on the date condition
        const filteredEvents = fetchedData.filter((event) => {
          const eventDate = new Date(event.data.date);
          return eventDate >= currentDate;
        });

        // Sort the filtered events by date
        const sortedEvents = filteredEvents.sort((a, b) => {
          // Parse the date strings and compare
          const dateA = new Date(a.data.date);
          const dateB = new Date(b.data.date);
          return dateA - dateB;
        });

        setEvents(sortedEvents);
      } catch (error) {
        console.log(error);
      }
    };

    getEventList();
  }, []);

  return events;
}

export default useEvents;
