import React from 'react'
import Navbar from '../Components/Navbar'
import SignUp from '../Components/SignUp'
import Footer from '../Components/Footer'
const SignUpPage = () => {
  return (
    <div>
        <Navbar/>
        <SignUp/>
        <Footer/>
    </div>
  )
}

export default SignUpPage ;