import { useState, useEffect } from 'react';
import { auth } from '../config/firebase';

export function useAuth() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    // Listen for Firebase authentication state changes
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
    });

    // Unsubscribe the listener when the component is unmounted
    return () => unsubscribe();
  }, []);

  return user;
}
