import './App.css';
import React from "react";
import { HashRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Home from './Pages/Home'
import AddEvent from './Pages/AddEvent'
import EventPage from './Pages/EventPage'
import LoginPage from './Pages/LoginPage'
import SignUpPage from './Pages/SignUpPage'
import MyEvents from './Pages/MyEvents'
import About from './Pages/About'
import Contact from './Pages/Contact'
import NotVerified from './Pages/NotVerified'
import {useAuth} from './Components/useAuth'

function App() {
  const user = useAuth() ;
  

  return (
    <Router>
        <div className="App">
           <Routes>
            {user === null ? 
             (
              <React.Fragment>
                <Route exact path="/*"  element={<Navigate to="/" />} />
                <Route path='/' element={<Home/>}/>
                <Route path='/about' element={<About/>}/>
                <Route path='/contact' element={<Contact/>}/>
                <Route path='/event-detail' element={<EventPage/>}/>
                <Route path='/login' element={<LoginPage/>}/>
                <Route path='/signup' element={<SignUpPage/>}/>
              </React.Fragment>
             ) :
             (
              <React.Fragment>
                {user?.uid === 'rm1c2QIoYBT3TELOr1b0rbEWX742' ? (
                  <React.Fragment>
                    <Route exact path="/*"  element={<Navigate to="/" />} />
                    <Route path='/' element={<Home/>}/>
                    <Route path='/list-of-not-verified' element={<NotVerified/>}/>
                    <Route path='/about' element={<About/>}/>
                    <Route path='/contact' element={<Contact/>}/>
                    <Route path='/event-detail' element={<EventPage/>}/>
                    <Route path='/addevent' element={<AddEvent/>}/>
                    <Route path='/my-events' element={<MyEvents/>}/>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Route exact path="/*"  element={<Navigate to="/" />} />
                    <Route path='/' element={<Home/>}/>
                    <Route path='/about' element={<About/>}/>
                    <Route path='/contact' element={<Contact/>}/>
                    <Route path='/event-detail' element={<EventPage/>}/>
                    <Route path='/addevent' element={<AddEvent/>}/>
                    <Route path='/my-events' element={<MyEvents/>}/>
                  </React.Fragment>
                )}
                
              </React.Fragment>
             )}
              
              
              
           </Routes>
        </div>
    </Router>
    
  );
}

export default App;
