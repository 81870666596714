import { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../config/firebase';

function useUsers() {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const userRef = collection(db, "users");
    const getUsersList = async () => {
      try {
        const response = await getDocs(userRef);
        const fetchedData = response.docs.map((doc) => ({
          id: doc.id,
          data: doc.data(),
        }));
        setUsers(fetchedData);
      } catch (error) {
        console.log(error);
      }
    };
    getUsersList();
  }, []);

  return users;
}

export default useUsers;
