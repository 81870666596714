import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CustomAlert from './CustomAlert';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { signInWithPopup,GoogleAuthProvider } from 'firebase/auth';
import './Style/Sign.css';
import { getAuth} from 'firebase/auth';
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../config/firebase';
import { app } from '../config/firebase'; 
import useUsers from './Users'
import google from '../Images/google.png'

const Login = () => {
  const users = useUsers()
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const closeAlert = () => {
    setShowAlert(false);
  };

  const handleEmailSignIn = async (e) => {
    e.preventDefault();
    if (!email || !password) {
      setAlertMessage('Email and password are required.');
      setShowAlert(true);
      
    }
    else {
      
      const auth = getAuth(app)
      try {
        navigate('/')
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        // User signed in successfully
        
      } catch (error) {
        setAlertMessage('Error signing in with email and password');
        setShowAlert(true);
      }
      }
  };
  const handleGoogleSignUp = async () => {
    const provider = new GoogleAuthProvider();
    const auth = getAuth(app);
  
    try {
      navigate('/')
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      const usersRef = collection(db, "users");
      
      const existingUser = users.find((u) => u.data.uid === user.uid);
      if (existingUser) {
        // console.log("User already exists. Cannot add again.");
        return;
      }
      
  
      // If the email is not found in the collection, proceed with sign-up
      await addDoc(usersRef, {
        uid: user.uid,
        email: user.email,
        // You can add more user data here, such as name, etc.
      });
      navigate('/')
    } catch (err) {
      
      setAlertMessage('Error signing in with Google');
      setShowAlert(true);
    }
  };
  const goSignUp = ()=> {
    navigate('/signup')
  }

  return (
    <div className='Login' style={{height:'100vh'}}>
      <div className="login-container">
      <div style={{textAlign:'center'}}><h2>Welcome Back</h2></div>
        <form onSubmit={handleEmailSignIn}>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button type="submit">Sign In with Email</button>
        </form>
        <br/><br />
        <div className='signuplink'>
          <p>Not a member ? <span onClick={goSignUp}>Signup now</span></p>
        </div>
        <button className='google-btn' onClick={handleGoogleSignUp}>
          <div style={{display:'flex', alignItems:'center',justifyContent:'center',position:'relative'}}>
            <img style={{maxWidth:'1rem', maxHeight:'1rem',position:'absolute',left:'1rem'}} src={google} alt="" />
            Continue with google
          </div>
        </button>
      </div>
      {showAlert && (
        <CustomAlert message={alertMessage} onClose={closeAlert} />
      )}
    </div>
  );
};

export default Login;
