import React from 'react';
import './Style/Section.css';
import aboutImg from '../Images/aboutImg.png'
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const AboutContainer = () => {
  const [contentRef, contentInView] = useInView({
    triggerOnce: true, // Animation triggers only once
    threshold: 0.2,    // Animation starts when 20% of the element is visible
  });

  const contentVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 1 } },
  };

  return (
    <div className='Section'>
      <div className='content-section' ref={contentRef}>
        <motion.div
          initial='hidden'
          animate={contentInView ? 'visible' : 'hidden'}
          variants={contentVariants}
        >
          <div>
            <h2 style={{color:'#c70039'}}>About Us</h2>
            <p>
            Welcome to Eventii, your ultimate destination for discovering and 
            engaging with the vibrant world of university events in Tunisia. 
            We understand that your college journey is not just about academics but 
            also about the exciting experiences that shape your campus life. 
            That's why we've created Eventii – your gateway to a plethora of events,
             workshops, seminars, 
            and gatherings that are happening across Tunisian universities.
            </p>
            <p>
            We are looking to enhance your skills, broaden your network, 
            or simply have a good time, Eventii is here to be your guide
            </p>
          </div>
        </motion.div>
      </div>
      <div className='images-section' style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
        <img src={aboutImg} alt="" style={{ maxWidth: '90%',maxHeight:'90%' }} /> 
      </div>
    </div>
  );
};

export default AboutContainer;
